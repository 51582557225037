<template>
    <div class="main">
        <!-- <Title><pre style="font-family:initial;">历思情 中国心</pre></Title> -->
        <div class="box">
            <div class="item" @click="goDetail(1)">
                <div><img class="img" :src="require('../../assets/Duty/public/1-1.jpg')"/></div>
                <div class="footer">
                    <div>
                        <p class="time1">2021</p>
                        <p class="time2">07-11</p>
                    </div>
                    <div class="title">
                        【我为群众办实事】福建历思司法鉴定所为市民提供司法鉴定公益服务
                    </div>
                </div>
            </div>
            <div class="item" @click="goDetail(2)">
                <div><img class="img" :src="require('../../assets/Duty/public/2-1.png')"/></div>
                <div class="footer">
                    <div>
                        <p class="time1">2021</p>
                        <p class="time2">06-01</p>
                    </div>
                    <div class="title">
                        疫情防控，历思公益在行动！疫情期间，免费提供酒精、消毒液等防疫用品...
                    </div>
                </div>
            </div>
        </div>
        <el-pagination
        background
        layout="prev, pager, next"
        :total="2">
        </el-pagination>
    </div>
</template>
<script>
export default {
    data(){
        return {
        }
    },
    methods:{
        goDetail(id){
            switch(id){
                case 1:
                    this.$router.push({
                        path:'/duty/public_detail/1'
                    })
                break;
                case 2:
                    this.$router.push({
                        path:'/duty/public_detail/2'
                    })
                break;
            }
        }
    }
}
</script>
<style scoped>
.main{
    width:80vw;
    margin:0 auto;
    max-width:1600px;
}
.img{
    width:25vw;
    max-width:500px;
    min-width:250px;
    height: 18vw;
    max-height: 400px;
    min-height: 200px;
}
.content{
    font-size:1.3rem;
    padding: 0 2rem;
}
.box{
    margin:3rem 0;
    display:flex;
    justify-content:space-around;
    flex-wrap:wrap;

}
.item{
    margin: 2rem 0 3rem 0;
    width:25vw;
    max-width:500px;
    min-width:250px;
    padding:10px;
}
.footer{
    display:flex;
    justify-content:space-around;
    padding:10px;
}
.time1{
    font-size:1.2rem;
}
.time2{
    font-size:1.5rem;
    color:#025B82;
    white-space:nowrap;
}
.title{
    font-size: 1.2rem;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    border-left:1px solid #b5b5b5;
    margin-left:5px;
}
.item:hover{
    background:#284C91;
    color:#fff;

}
</style>